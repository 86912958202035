import loc from "../localization";

export const initPermissions = {
  itemsPage: false,
  itemsPageEditButton: false,
  itemsPageActionButton: false,
  itemPageActionButtonEdit: false,
  itemsPageActionButtonDownload: false,
  itemsPageActionButtonDelete: false,
  itemsPageActionButtonPrices: false,
  itemsPageActionButtonOrder: false,
  itemsPageActionButtonPricelist: false,
  itemsPageActionButtonUpload: false,
  responsibleWorkersPage: false,
  responsibleWorkersPageActionButton: false,
  priceListsPage: false,
  priceListsPageActionButton: false,
  priceListsPageActionButtonExportToExel: false,
  priceListNamesPage: false,
  priceListNamesPageActionButton: false,
  deliveryTermsPage: false,
  deliveryTermsPageActionButton: false,
  currenciesPage: false,
  currenciesPageActionButton: false,
  signaturesPage: false,
  signaturesPageActionButton: false,
  groupsOfProdutsPage: false,
  groupsOfProdutsPageActionButton: false,
  groupsOfItemsPageActionButton: false,
  groupsOfItemsPageMoveButton: false,
  tradeMarksPage: false,
  exchangeRatesUsdPage: false,
  exchangeRatesUsdPageActionButton: false,
  exchangeRatesGbpPage: false,
  exchangeRatesGbpPageActionButton: false,
  shipmentsPage: false,
  shipmentsPageBasicActionButton: false,
  shipmentsPageCertificatesActionButton: false,
  shipmentsPageLicensesActionButton: false,
  counterpartyPage: false,
  counterpartyPageActionButton: false,
  counterpartyPageAddActionButton: false,
  counterpartyPageAddSpecialPriceButton: false,
  counterpartyPageForecastButton: false,
  counterpartyPageAgreementsButton: false,
  counterpartyPageDetailsButton: false,
  categoriesOfItemsPageActionButton: false,
  customsCodesPage: false,
  intrastatCustomsCodesPageActionButton: false,
  licensePage: false,
  licensePageActionButton: false,
  rexDevicesPage: false,
  certificatesPage: false,
  contractsPage: false,
  orderFormsPage: false,
  orderFormsSparePage: false,
  orderFormsPageActionButton: false,
  distributorOrdersPageActionButton: false,
  orderFormsPageClearStatusesActionButton: false,
  ordersPage: false,
  ordersSparePage: false,
  ordersPageActionButton: false,
  paymentsPage: false,
  paymentsPageActionButton: false,
  shipmentRequestsPage: false,
  shipmentRequestsPageActionButton: false,
  notificationsPage: false,
  historyPage: false,
  orderFormsAdditionsPage: false,
  healthcheckLink: false,
  uploadShipmentSchedulePage: false,
  priceChangeNotifications: false,
  priceCalculationPage: false,
  priceCalculationEdit: false,
  oneTimePricesPage: false,
  nomenclatureToExcelPage: false,
  profitabilityOfCompaniesPage: false,
  uploadPrices: false,
  accessToPortalPage: false,
  accessSettingsToSales: false,
  faqPageActionButton: false,
  shipmentControlLogActionButton: false,
  posPage: false,
  distributorOrdersPosPage: false,
  shipmentRequestsPosPage: false,
  reports: {
    shipmentsToBuyers: false,
    debts: false,
    targets: false,
    quota: false,
    statistics: false,
    internalReports: false,
    buyerCard: false,
    enterTargets: false,
    calculationOfValues: false,
    cashQuota: false,
    productQuota: false,
    reportOnTheResponsible: false,
    executionOfOrders: false,
    abcAnalysis: false,
    wereTheDevicesWereSold: false,
    shipmentAmountsOfNewProducts: false,
    findingDeviceNumbers: false,
    detailedReport: false,
    forecast: false,
    assortimentAnalysis: false,
    shippingSchedule: false,
    executionOfOrdersByCounterparty: false,
    licenseDevices: false,
    serialNumbers: false,
    yukon: false,
    shippedLicensesReport: false,
    yukonLicenseEu001: false,
    forecastReport: false,
    pulsar: false,
    polaris: false,
    averageWeightedMarkup: false,
  },
};

export const monthsObj = () => ({
  1: loc.months.january,
  2: loc.months.february,
  3: loc.months.march,
  4: loc.months.april,
  5: loc.months.may,
  6: loc.months.june,
  7: loc.months.july,
  8: loc.months.august,
  9: loc.months.september,
  10: loc.months.october,
  11: loc.months.november,
  12: loc.months.december,
});
